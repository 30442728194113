import "uikit/dist/css/uikit.min.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat"
import "./src/styles/global.scss"

export const onServiceWorkerUpdateReady = () => {
	const answer = window.confirm(
		`This application has been updated. ` +
			`Reload to display the latest version?`
	)

	if (answer === true) {
		window.location.reload()
	}
}
