module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HOMKAM","short_name":"HOMKAM","description":"HOMKAM Jasmine Rice 100%   No mixed   Delicious, Flavorful, Soft; even if it is cool.","start_url":"/","background_color":"#FFFFFF","theme_color":"#A99853","display":"standalone","icon":"static/logo/favicon.png","icon_options":{"purpose":"any maskable"},"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"afa3070019f4f84ed983bc490e5b505c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"importWorkboxFrom":"cdn"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
